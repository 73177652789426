<template>
  <main class="container">
    <div class="header h3 mt-2">
          Almacenes
        <button @click="showNewModal=true; newItem = {}" class="btn-2 float-end"><i class="fas fa-plus me-2"/> Agregar Nuevo</button>
    </div>


    <!-- ADD NEW MODAL -->
    <b-modal id="modal-1" size="lg" title="Agregar Almacén" class="p-3" centered v-model="showNewModal" hide-footer>
        <form @submit.prevent="postData()" class="mx-3">
            <label class="text-green-2">Nombre:</label>
            <input type="text" class="form-control bg-white border-secondary mb-4" required v-model="newItem.name">

            <div class="my-2" style="position:relative">
                <label class="text-green-1 fw-bold">Campos </label>
                <hr class="hr-green">
                
                <div class="row">
                    <div class="col">
                        <span class="text-secondary">Nombre</span><br>
                        <input type="text" class="form-control bg-white border-secondary" v-model="newField.name">
                    </div>
                    <div class="col">
                        <span class="text-secondary">Tipo</span><br>
                        <select class="form-select bg-white border-secondary" v-model="newField.dataType">
                            <option v-for="type in fieldTypes" :key="type.id">{{type.name}}</option>
                        </select>
                    </div>
                    <div class="col-1 text-start">
                        <br>
                        <button  type="button" class="text-center ms-2 shadow" style="min-width:30px; width:35px; height:35px; padding:4px;" 
                                :class="!(newField.name && newField.dataType) ? 'btn-3 bg-secondary ':'btn-1 '" 
                                :disabled="!(newField.name && newField.dataType)" @click="addField()">
                             <i class="fas fa-plus"/>
                        </button>
                    </div>
                </div>
                

                <table class="w-100 mt-3" v-if="newItem.fields">
                    <tr v-for="(field, i) in newItem.fields" :key="i">
                        <td class="ps-3 text-darkgreen">{{field.name}}</td>
                        <td class="ps-3 text-darkgreen">{{field.dataType}}</td>
                        <td class="text-end"> <i class="fas fa-trash text-danger"/> </td>
                    </tr>
                </table>
            </div>

            <div class="mt-5 bg-white text-end" style="margin:-16px -32px; padding:1rem 0px">
                <button class="btn-3  me-2" type="button" @click="showNewModal=false">Cancelar <i class="fas fa-times ms-2"></i></button>
                <button class="me-4" type="submit" :class="(newItem.fields && newItem.fields.length > 0) ? 'btn-1' : 'btn-2 bg-secondary'"
                        :disabled="!(newItem.fields && newItem.fields.length > 0)">Guardar <i class="fas fa-save ms-2"></i></button>
            </div>
        </form>
    </b-modal>

    <!-- EDIT MODAL -->
    <b-modal id="modal-2" size="lg" title="Editar Almacén" class="p-3" centered v-model="showEditModal" hide-footer>
        <form @submit.prevent="putData()" class="mx-3">
            <label class="text-green-2">Nombre:</label>
            <input type="text" class="form-control bg-white border-secondary mb-4" required v-model="selectedItem.name">

            <div class="my-2" style="position:relative">
                <label class="text-green-1 fw-bold">Campos </label>
                <hr class="hr-green">
                
                <div class="row">
                    <div class="col">
                        <span class="text-secondary">Nombre</span><br>
                        <input type="text" class="form-control bg-white border-secondary" v-model="newFieldForEdit.name">
                    </div>
                    <div class="col">
                        <span class="text-secondary">Tipo</span><br>
                        <select class="form-select bg-white border-secondary" v-model="newFieldForEdit.dataType">
                            <option v-for="type in fieldTypes" :key="type.id">{{type.name}}</option>
                        </select>
                    </div>
                    <div class="col-1 text-start">
                        <br>
                        <button  type="button" class="text-center ms-2 shadow" style="min-width:30px; width:35px; height:35px; padding:4px;" 
                                :class="!(newFieldForEdit.name && newFieldForEdit.type) ? 'btn-3 bg-secondary ':'btn-1 '" 
                                :disabled="!(newFieldForEdit.name && newFieldForEdit.dataType)" @click="addEditField()">
                             <i class="fas fa-plus"/>
                        </button>
                    </div>
                </div>
                

                <table class="w-100 mt-3" v-if="selectedItem.fields">
                    <tr v-for="(field, i) in selectedItem.fields" :key="i">
                        <td class="ps-3 text-darkgreen">{{field.name}}</td>
                        <td class="ps-3 text-darkgreen">{{field.dataType}}</td>
                        <td @click="deleteField(field.id, i)" class="text-end"> <i class="fas fa-trash text-danger"/> </td>
                    </tr>
                </table>
            </div>

            <div class="mt-5 bg-white text-end" style="margin:-16px -32px; padding:1rem 0px">
                <button class="btn-3  me-2" type="button" @click="showEditModal=false">Cancelar <i class="fas fa-times ms-2"></i></button>
                <button class="me-4" type="submit" :class="(selectedItem.fields && selectedItem.fields.length > 0) ? 'btn-1' : 'btn-2 bg-secondary'"
                        :disabled="!(selectedItem.fields && selectedItem.fields.length > 0)">Guardar <i class="fas fa-save ms-2"></i></button>
            </div>
        </form>
    </b-modal>

    <!-- DETAILS -->
    <div class="row mt-4 mx-1 rounded" style="min-height:75vh; background:rgba(0,0,0,0.4);">
        <div class="col-12 p-3">
            <table class="table ">
                <thead>
                    <th class="fw-bold">ID</th>
                    <th class="fw-bold">NOMBRE</th>
                    <th class="text-end fw-bold pe-5">ACCIONES</th>                    
                </thead>
                <tbody>

                </tbody>
                <tr v-for="item in items" :key="item.id" >
                   <td>{{item.id}}</td>
                   <td>{{item.name}}</td>
                   <td class="text-end pe-3">
                       <button class="btn-1" title="Categorías">
                           <i class="fas fa-list" @click="$router.push(`/warehouses/${item.id}/categories`)" ></i>
                        </button>
                       <button class="btn-1 ms-2" title="Editar" @click="showEdit(item)">
                           <i class="fas fa-pen "></i>
                        </button>
                       <button class="btn-1 ms-2" title="Eliminar" @click="deleteItem(item)">
                           <i class="fas fa-trash "></i>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
  </main>
</template>

<script>
export default {
    data(){
        return {
            items:[
            // {id:1, name: "Vehículos"},
            // {id:2, name: "Covid"},
            // {id:3, name: "Uniformes"},
            // {id:4, name: "Consignación"},
            // {id:5, name: "Estructural"}
            ],
            newField:{},
            fieldTypes:[
                {id:1, name:"Texto"},
                {id:2, name:"Número"},
                {id:3, name:"Fecha"}
            ],
            
            showNewModal:false,
            newItem:{},

            showEditModal:false,
            selectedItem:{},
            newFieldForEdit:{},
            table: 'warehouses',
        }
    },
    async mounted(){
        this.getData()
    },
    methods:{
        async deleteField(id, i){
            const {isConfirmed} = await this.$swal({
                title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
                icon:"question",
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar',
                reverseButtons: true
            })
            if(isConfirmed){
                try {
                    let res = await this.$store.dispatch('delete', {path: 'warehouseFields/delete/' + id});
                    if (res.error){
                        this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                    }
                    else{
                        this.getData()
                        this.selectedItem.fields.splice(i,1)
                    }
                } catch (error) {
                    this.items = []
                }                    
            }
        },        
        async showEdit(item){
            this.selectedItem=item;
            this.showEditModal=true;
        },
        async getData(){
            try {
                this.items = [];                
                let res = await this.$store.dispatch('get', {path: this.table + '/getAll'});
                if (res.length >0){
                    this.items = res
                }
            } catch (error) {
                this.items = []
                console.error('error', error)
            }
        },       
        async postData(){
            try {
                //paso1 insertar dato almacen
                let data = Object.assign({} , this.newItem) 
                delete(data.fields)
                let res = await this.$store.dispatch('post', {path: this.table + '/new' , data: data});
                //paso2 insertar campos en warehouseFields
                let dataFields = Object.assign({} , this.newItem)
                for await(let element of dataFields.fields){
                    element.warehouseId = res.insertId
                    let resField = await this.$store.dispatch('post', {path: 'warehouseFields/new' , data: element});
                }
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    this.getData()
                    this.showNewModal=false
                    this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                }
            } catch (error) {
                this.items = []
                console.error('error--->', error)
            }        
        },
        async putData(){
            try {
                let data = Object.assign({} , this.selectedItem) 
                delete(data.id)
                delete(data.fields)
                let res = await this.$store.dispatch('put', {path: this.table + '/modify/'+ this.selectedItem.id , data: data});
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    this.showEditModal=false
                    this.getData()
                    this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
                }
            } catch (error) {
                this.items = []
                console.error('error--->', error)
            }
        },       
        async deleteItem(item){
            const {isConfirmed} = await this.$swal({
                title:"Confirmar", text:"¿Desea realmente eliminar el registro?", 
                icon:"question",
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Confirmar',
                reverseButtons: true

            })
            if(isConfirmed){
                try {
                    let res = await this.$store.dispatch('delete', {path: this.table + '/delete/' + item.id});
                    if (res.error){
                        this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                    }
                    else{
                        this.getData()
                        this.$swal({title:"Aviso", text:"Eliminado correctamente", icon:"success"})
                    }
                } catch (error) {
                    this.items = []
                    console.error('error--->', error)
                }                    
            }
        },
        addField(){
            if (!this.newItem.fields) 
                this.newItem.fields = []
                    
            this.newItem.fields.push(this.newField)
            this.newField = {}
        },
        async addEditField(){
            if (!this.selectedItem.fields) 
                this.selectedItem.fields = []
            let field = Object.assign({} , this.newFieldForEdit)
            field.warehouseId = this.selectedItem.id

            this.selectedItem.fields.push(this.newFieldForEdit)
            try {
                let res = await this.$store.dispatch('post', {path: 'warehouseFields/new' , data: field });
                if (res.error){
                    this.$swal({title:"Aviso", text:"Error: " + res.error , icon:"error"})
                }
                else{
                    this.getData()
                    this.newFieldForEdit = {}
                }
            } catch (error) {
                this.items = []
                console.error('error--->', error)
            }              
        },
    }

}
</script>

<style>

</style>